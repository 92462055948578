$primary: #ea5a0b;
$secondary: #999999;
$light: #ecebeb;
$gray-900: #292724;

//theme colors
//$orange: #ea5a0b;
//$morkgra: #292724;
//$mellangra: #999999;
//$ljusgra: #d9d7d7;

$spinner-border-width: 0.25rem;

$min-contrast-ratio: 2.7 !default;
$card-border-radius: 14px;
$card-inner-border-radius: 12px;

//$font-family-sans-serif: "Roboto";

@import "bootstrap/scss/bootstrap";
@import "common";
@import "quicksearch";
@import "typeahead";
@import "chartist";
